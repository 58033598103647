import {
  // WeatherApp1,
  // WeatherApp2,
  // MovieFinder1,
  // MovieFinder2,
  AgScout1,
  AgScout2,
  OpsPanel1,
  OpsPanel2,
  // Healthdoc1,
  // Healthdoc2,
  BookFinder,
  NestAcademy1,
  // NestAcademy2,
  // NestAcademy3,
  // NestAcademy4,
  CarSimulation,
  // AdminCarSimulation1,
  // AdminCarSimulation2,
  QueSpaceApp1,
  QueSpaceApp2,
  QueSpaceAppAdmin1,
  QueSpaceAppAdmin2,
  WhistleblowerApp,
  Kemenkeu,
  CISWebApp,
  QueSpaceApp3,
  QueSpaceApp4,
  QueSpaceAppAdmin3,
  QueSpaceAppAdmin4,
} from '../../../images';

export const portfolioData = [
  // {
  //   id: 2,
  //   name: "Movie Finder!",
  //   description:
  //     "Movie Finder is a project for my submission at Dicoding Academy (Belajar Fundamental Front-End Web) course, built with JavaScript, HTML, CSS, Webpack and TMDB's API, you can get information about your favorite movie.      ",
  //   github: "https://github.com/hadyd/MovieFinder",
  //   demo: "https://moviefinder.hadyd.dev/",
  //   image: [MovieFinder2, MovieFinder1],
  // },
  {
    id: 1,
    name: 'Nest Academy',
    description: `Nest Academy is an academy that accelerates talent's skills related to IT to fulfill industry needs. I developed the Nest Academy Event Page Website.`,
    stack: 'Next.js, JavaScript, Chakra UI, Strapi Headless CMS, etc.',
    role: 'Frontend Engineer',
    image: [NestAcademy1],
    demo: 'https://nestacademy.id/events',
    flag: 'nest-academy',
  },
  {
    id: 2,
    name: 'Book Finder',
    description:
      'Book Finder is a simple book search application built using React JS & Google Books API. Users can search for books and save them to their wishlist.',
    stack: 'React.js, Redux, JavaScript, React Bootstrap, etc.',
    role: 'Frontend Engineer',
    image: [BookFinder],
    demo: 'https://bookfinder.hadyd.dev/',
  },
  {
    id: 3,
    name: 'Ministry of Finance, Republic of Indonesia Web',
    description: 'Maintaining and bug-fixing the website of the Ministry of Finance of the Republic of Indonesia.',
    role: 'Frontend Developer',
    stack: 'Angular, TypeScript, Tailwind, etc.',
    demo: 'https://www.kemenkeu.go.id/',
    image: [Kemenkeu],
  },
  {
    id: 4,
    name: 'Clinic Information System (CIS)',
    description: 'Develop CIS web application. The app enables clinic staff to manage patient schedules, medical records, patient registrations, and other features.',
    role: 'Frontend Developer',
    stack: 'React.js, JavaScript, PrimeReact, etc.',
    demo: ' ',
    image: [CISWebApp],
  },
  {
    id: 5,
    name: 'Window Film Simulation ICE-U',
    description:
      'ICE-U Window Film Simulation is a website used to simulate car window film. Users can view different options for the car, price, and type of window film.',
    stack: 'React.js, JavaScript, Chakra UI, etc.',
    role: 'Full-Stack Developer',
    image: [CarSimulation],
    demo: '',
  },
  {
    id: 6,
    name: 'Whistleblower APP',
    description:
      'Translating the design into functional code for Whistleblower Page Asia Pulp and Paper (APP).',
    role: 'Frontend Developer',
    image: [WhistleblowerApp],
    demo: '  ',
    stack: 'HTML, SCSS, Bootstrap, Gulp & jQuery, etc.',
  },
  {
    id: 7,
    name: 'QueSpace',
    description:
      'QueSpace is a digital platform for academic consultation between students and campus service units. With this application, students can consult with various campus service units such as Biro Administrasi Pembelajaran, Biro Administrasi Keuangan, and more. Additionally, students can also access information about academic activities.',
    stack: 'React Native & Firebase',
    role: 'Full-Stack Mobile Developer',
    image: [QueSpaceApp1, QueSpaceApp2, QueSpaceApp3, QueSpaceApp4],
    demo: 'https://play.google.com/store/apps/details?id=com.quespace',
    isGooglePlay: true,
  },
  {
    id: 8,
    name: 'QueSpace for Unit Pelayanan',
    description:
      'QueSpace for Unit Pelayanan is a digital platform for academic consultation between campus service units and students. This application allows campus service units to respond to student consultations online. Additionally, campus service units can share information about academic activities.',
    stack: 'React Native & Firebase',
    role: 'Full-Stack Mobile Developer',
    image: [QueSpaceAppAdmin1, QueSpaceAppAdmin2, QueSpaceAppAdmin3, QueSpaceAppAdmin4],
    demo: 'https://play.google.com/store/apps/details?id=com.quespaceadmin',
    isGooglePlay: true,
  },
  {
    id: 9,
    name: 'AgScout Backoffice',
    description:
      'Backoffice AgScout is a CROWDE web application used by internal teams for task assignment, displaying task lists, monitoring progress, version control, accessing dashboard data, and more.',
    role: 'Frontend Engineer',
    stack: 'React.js, React Bootstrap, etc.',
    // demo: 'https://agent.crowde.co/',
    image: [AgScout1, AgScout2],
  },
  {
    id: 10,
    name: 'Ops Panel',
    description:
      'Ops Panel is a web application for CROWDE internal team. Users can view and update portfolio content data, download OJK report data, and more.',
    role: 'Frontend Engineer',
    stack: 'React.js, Redux, SCSS, Ant Design, etc.',
    // demo: 'https://opspanel.crowde.co/',
    demo: ' ',
    image: [OpsPanel1, OpsPanel2],
  },
  // {
  //   id: 1,
  //   name: 'Cuaca Sekarang?',
  //   description:
  //     'Cuaca Sekarang is a simple Weather App built with JavaScript using React JS library, SASS (Syntactically Awesome Stylesheet) and Weather API, you can search weather data based on the location you are looking for.',
  //   stack: 'React JS',
  //   role: 'Frontend Engineer',
  //   image: [WeatherApp2, WeatherApp1],
  //   demo: 'https://appweather.hadyd.dev/',
  // },
  // {
  //   id: 6,
  //   name: 'Healthdoc',
  //   description:
  //     'Healthdoc is a health consulting app. Users can consult with doctors using real-time chat, view chat history, view hospital lists, etc.',
  //   stack: 'React Native & Firebase',
  //   role: 'Full-Stack React Native Developer',
  //   image: [Healthdoc1, Healthdoc2],
  //   demo: 'https://github.com/hadyd/healthdoc',
  // },
  // {
  //   id: 8,
  //   name: 'Admin Web App ICE-U',
  //   description:
  //     'Admin Web App ICE-U is a web app for admin ICE-U Window Film. Admin can see list car models, search car models, add new car model, edit car model, and delete car model.',
  //   stack: 'React JS, Chakra UI, Strapi & PostgreSQL',
  //   role: 'Full-Stack Developer',
  //   image: [AdminCarSimulation1, AdminCarSimulation2],
  //   demo: 'https://admin-app.iceu-microsite.com/',
  // },
];
