import React from 'react';
import { Hero, SEO } from '../../components';
import { HadydIMG } from '../../images';

export default function Home() {
  return (
    <div>
      <SEO
        title="Profile | Anur Al Hadyd - Frontend Developer"
        description="Hi! I’m Hadyd. I am an experienced front-end developer with several years of experience. I specialize in creating visually appealing and user-friendly website and website applications using the latest web technologies. Skills & Tools: HTML, CSS, SCSS, JavaScript, jQuery, React JS, React Native, Redux, Strapi, Bootstrap, Chakra UI, Antd Design, Figma, Git, GitHub, GitLab, Firebase, Netlify & Vercel, etc."
        name="Anur Al Hadyd"
        type="article"
        keywords="Anur Al Hadyd, Hadyd, Frontend Developer, JavaScript Developer, Software Developer, Programmer, Engineer "
        image={HadydIMG}
      />
      <Hero
        title="Hi! I’m Hadyd 👋"
        desc="Experienced frontend developer specializing in React.js for web applications. Skilled in coding, debugging, and collaborating with backend developers and team members to enhance user experiences. Committed to staying updated with the latest technologies and continuously improving skills through ongoing learning."
      />
    </div>
  );
}
