import { Routes } from './config';
import './index.scss';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <div className="wrapper-pages">
      <HelmetProvider>
        <Routes />
      </HelmetProvider>
    </div>
  );
}

export default App;
