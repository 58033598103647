import React from 'react';
import { PortfolioCard, SEO } from '../../components';
import { portfolioData } from '../../config/data';
import { HadydIMG } from '../../images';

export default function Portfolio() {
  return (
    <div>
      <SEO
        title="Portfolio | Anur Al Hadyd - Frontend Developer"
        description="Some of Anur Al Hadyd portfolio as Frontend Developer: AgScout Backoffice, Ops Panel, Book Finder, Healthdoc, Nest Academy. Tech Stack: HTML, CSS, SCSS, JavaScript, React JS, React Native, Redux, Chakra UI & Bootstrap, etc."
        name="Anur Al Hadyd"
        type="article"
        keywords="Anur Al Hadyd Portfolio, Frontend Developer Portfolio, JavaScript Developer Portfolio, Software Developer Portfolio, Programmer Portfolio, Engineer Portfolio"
        image={HadydIMG}
      />
      <PortfolioCard data={portfolioData} />
    </div>
  );
}
