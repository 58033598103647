import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './index.scss';
import WorkIcon from '@material-ui/icons/Work';
import { SEO } from '../../components';
import { HadydIMG } from '../../images';

export default function Experience() {
  return (
    <div className="container-md">
      <SEO
        title="Experience | Anur Al Hadyd - Frontend Developer"
        description="Anur Al Hadyd experience as a Frontend Developer. Develop user interface, translate figma designs into code, bug fixing and improvements, Integrate frontend with backend API, collaborate with UI/UX Designer, Backend , QA, and Product teams, etc."
        name="Anur Al Hadyd"
        type="article"
        keywords="Anur Al Hadyd Experience, Frontend Developer Experience, JavaScript Developer Experience, Software Developer Experience, Programmer Experience, Engineer Experience"
        image={HadydIMG}
      />

      <section className="experience-wrapper">
        <div className="title-text position">Work Experience</div>

        <VerticalTimeline>
          <VerticalTimelineElement
            contentStyle={{ background: '#ff7c57', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  #ff7c57' }}
            date="Feb 2024 - Present"
            iconStyle={{ background: '#1abc9c', color: '#ffffff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Frontend Developer at PT. Mirae Asset Sekuritas Indonesia
            </h3>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: '#ff7c57', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  #ff7c57' }}
            date="Dec 2023 - Feb 2024"
            iconStyle={{ background: '#1abc9c', color: '#ffffff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Frontend Developer at PT. Infosys Solusi Terpadu
            </h3>
            <p>
              Responsible for developing user interface websites, bug fixing,
              integrating frontend with APIs, collaborating with UI/UX
              designers, backend developers, software quality assurance, project
              managers, and other team members. Below are some projects I have
              been involved.
            </p>
            <p>
              - Develop & Bug Fixing Portal Web App, Victoria Bank <br />-
              Develop & Bug Fixing IB (Internet Banking) Web App, Victoria Bank
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: '#ff7c57', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  #ff7c57' }}
            date="Sept 2022 - Nov 2023"
            iconStyle={{ background: '#1abc9c', color: '#ffffff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Frontend Developer at SALT Indonesia
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">at SALT</h4> */}
            <p>
              Responsible for developing user interface websites, bug fixing,
              integrating frontend with APIs, collaborating with UI/UX
              designers, backend developers, software quality assurance, project
              managers, and other team members. Below are some projects I have
              been involved.
            </p>
            <p>
              {/* Projects: Clinic Information System Web App Erha Clinic, Ministry
              of Finance, Republic of Indonesia Web, Whistleblower, Asia Pulp
              and Paper Sinar Mas, Aroma Relief Landing Page, Paseo Website,
              Enterprise Risk Management System, BSI, etc. */}
              - Develop Enterprise Risk Management System Web App, Bank Syariah
              Indonesia <br />
              - Develop Clinic Information System (CIS) Web App, Erha Clinic,
              Arya Noble <br />- Maintenance & Bug Fixes Ministry of Finance
              Republic of Indonesia Web
            </p>
            {/* <p>
              Tech Stacks: React.js, JavaScript, HTML, CSS, SCSS, Bootstrap,
              Git, Gitlab, GitHub, etc.
            </p> */}
          </VerticalTimelineElement>

          <VerticalTimelineElement
            contentStyle={{ background: '#ff7c57', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  #ff7c57' }}
            date="Mar 2022 - Jun 2022"
            iconStyle={{ background: '#1abc9c', color: '#ffffff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Frontend Engineer at DOT Indonesia
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">at SALT</h4> */}
            <p>
              Responsible for developing user interface websites, bug fixing,
              integrating frontend with APIs, collaborating with UI/UX
              designers, backend developers, software quality assurance, project
              managers, and other team members, while also actively learning new
              technologies. Below are some projects I have been involved.
            </p>
            <p>- Develop Nest Academy Web & Revamp Web DOT Indonesia.</p>
            {/* <p>This is an intern position.</p>
            <p>
              Tech Stacks: Next.js, JavaScript, Chakra UI, Strapi, Git, Gitlab,
              etc.
            </p> */}
          </VerticalTimelineElement>

          <VerticalTimelineElement
            contentStyle={{ background: '#ff7c57', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  #ff7c57' }}
            date="June 2020 - Sept 2022"
            iconStyle={{ background: '#1abc9c', color: '#ffffff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Frontend Developer at CROWDE
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">at CROWDE</h4> */}
            <p>
              Responsible for developing user interface websites, bug fixing,
              integrating frontend with APIs, collaborating with UI/UX
              designers, backend developers, software quality assurance, project
              managers, and other team members, while also actively learning new
              technologies. Below are some projects I have been involved.
            </p>
            <p>
              - Develop Web App for Internal Team CROWDE (OpsPanel, AgScout &
              AgSales Web App)
            </p>
            {/* <p>
              Tech Stacks: React JS, Redux, JavaScript, HTML, CSS, SCSS, Ant
              Design, React Bootstrap, Git, etc.
            </p> */}
          </VerticalTimelineElement>
        </VerticalTimeline>
      </section>
    </div>
  );
}
