import React from 'react';
import { ContactCard } from '../../components';
import { SEO } from '../../components';
import { HadydIMG } from '../../images';

export default function Contact() {
  return (
    <div>
      <SEO
        title="Contact | Anur Al Hadyd - Frontend Developer"
        description="Contact Anur Al Hadyd. I’m Frontend Developer with more than several years of experience in developing web apps using React JS. Skills & Tools: HTML, CSS, SCSS, JavaScript, jQuery, React JS, React Native, Redux, Strapi, Bootstrap, Chakra UI, Antd Design, Figma, Git, GitHub, GitLab, Firebase, Netlify & Vercel, etc."
        name="Anur Al Hadyd"
        type="article"
        keywords="Anur Al Hadyd Contact, Frontend Developer Contact, JavaScript Developer Contact, Software Developer Contact, Programmer Contact, Engineer Contact"
        image={HadydIMG}
      />
      <ContactCard title="Contact" email="hadyd.arl@gmail.com" />
    </div>
  );
}
