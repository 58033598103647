import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Viewer from 'react-viewer';
import { GetInGooglePlay } from '../../images';

export default function PortfolioCard({ data }) {
  let portfolioData = data;

  const [visible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);

  const handleImagePreview = (images, name) => {
    let myArray = images.map((str, index) => ({
      id: index + 1,
      src: str,
      alt: name,
    }));
    setPreviewImage(myArray);
    setVisible(true);
  };

  console.log(previewImage, 'PREVIEW');
  return (
    <div className="container-md">
      <section className="portfolio-wrapper">
        <div className="justify-content">
          <h1>Portfolio</h1>
        </div>

        <div className="container">
          <div className="row">
            {portfolioData.map((item, index) => (
              <div className="col-sm-6" key={index}>
                <div className="card">
                  <div className="card-body">
                    <div className="slide-container">
                      <Slide
                        easing="ease"
                        autoplay={false}
                        arrows={item.image.length > 1 && true}
                      >
                        {item.image.map((img, index) => (
                          <img
                            key={index}
                            src={img}
                            alt={item.name}
                            onClick={() => {
                              handleImagePreview(item.image, item.name);
                            }}
                            style={{ cursor: 'zoom-in' }}
                          />
                        ))}
                      </Slide>
                    </div>
                    <div className="warapper-desc">
                      <h5 className="card-title">{item.name}</h5>
                      <p className="card-text">{item.description}</p>
                      {item.subDesc ? (
                        <p className="card-text">{item.subDesc}</p>
                      ) : null}
                      {/* <span className="card-text">Role: {item?.role}</span> */}
                      <p className="card-text">Tech Stack: {item?.stack}</p>
                      <div>
                        {item?.isGooglePlay ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Link
                              to={{ pathname: item.demo }}
                              target="_blank"
                              rel="noopener"
                              className="demo-link"
                            >
                              <img
                                src={GetInGooglePlay}
                                alt={item.name}
                                style={{ width: '200px' }}
                              />
                            </Link>
                          </div>
                        ) : item.demo ? (
                          <Link
                            to={{ pathname: item.demo }}
                            target="_blank"
                            rel="noopener"
                            className="demo-link"
                          >
                            {item.flag === 'nest-academy'
                              ? 'https://nestacademy.id/'
                              : item.demo}
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <Viewer
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
              images={previewImage}
            />
          </div>
          <div className="justify-content see-more">
            <button className="btn btn-outline">
              <div className="d-flex align-items-center position">
                <Link
                  to={{
                    pathname: 'https://github.com/hadyd',
                  }}
                  target="_blank"
                  rel="noopener"
                  className="link-more"
                >
                  See More
                </Link>
              </div>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
