import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './index.scss';
import SchoolIcon from '@material-ui/icons/School';
import { SEO } from '../../components';
import { HadydIMG } from '../../images';

export default function Education() {
  return (
    <div className="container-md">
      <SEO
        title="Education | Anur Al Hadyd - Frontend Developer"
        description="Impact Byte Coding Bootcamp (Full Stack Web Developer) Focused on learning JavaScript, develop front-end web applications using React JS, web design using Figma, integrating front-end with APIs and build REST APIs using Go. Mercu Buana University (Information Systems) Information systems major."
        name="Anur Al Hadyd"
        type="article"
        keywords="Anur Al Hadyd Education, Frontend Developer Education, JavaScript Developer Education, Software Developer Education, Programmer Education, Engineer Education"
        image={HadydIMG}
      />
      <section className="education-wrapper">
        <div className="title-text position">Education</div>

        <VerticalTimeline>
          <VerticalTimelineElement
            contentStyle={{ background: '#ff7c57', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  #ff7c57' }}
            date="Mar 2020 - May 2020"
            iconStyle={{ background: '#3498db', color: '#ffffff' }}
            icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Full Stack Web Developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Impact Byte Coding Bootcamp
            </h4>
            <p>
              Focused on learning Javascript for web applications, developing
              front-end web applications using React, designing websites using
              Figma, integrating the front-end with APIs & build REST APIs using
              Golang. Go.
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            contentStyle={{ background: '#ff7c57', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '7px solid  #ff7c57' }}
            date="2019 - 2023"
            iconStyle={{ background: '#3498db', color: '#ffffff' }}
            icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Information Systems
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Mercu Buana University
            </h4>
            <p>Information Systems Major.</p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </section>
    </div>
  );
}
