import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Footer, Navbar } from '../../components';
import {
  Home,
  Portfolio,
  Experience,
  Contact,
  Education,
  QueSpacePrivacy,
  QueSpaceUnitPelayananPrivacy,
} from '../../pages';

export default function Routes() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/quespace-privacy-policy">
          <QueSpacePrivacy />
        </Route>
        <Route path="/quespace-unit-pelayanan-privacy-policy">
          <QueSpaceUnitPelayananPrivacy />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/experience">
          <Experience />
        </Route>
        <Route path="/education">
          <Education />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}
